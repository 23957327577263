enum ActionTypes {
    GET_EMPLOYEES = "GET_EMPLOYEES",
    GET_EMPLOYEES_SUCCESS = "GET_EMPLOYEES_SUCCESS",
    GET_EMPLOYEES_FAIL = "GET_EMPLOYEES_FAIL",
    GET_EMPLOYEE = "GET_EMPLOYEE",
    GET_EMPLOYEE_SUCCESS = "GET_EMPLOYEE_SUCCESS",
    GET_EMPLOYEE_FAIL = "GET_EMPLOYEE_FAIL",
    ADD_NEW_EMPLOYEE = "ADD_NEW_EMPLOYEE",
    ADD_NEW_EMPLOYEE_SUCCESS = "ADD_NEW_EMPLOYEE_SUCCESS",
    ADD_NEW_EMPLOYEE_FAIL = "ADD_NEW_EMPLOYEE_FAIL",
    UPDATE_SALARY = "UPDATE_SALARY",
    UPDATE_SALARY_SUCCESS = "UPDATE_SALARY_SUCCESS",
    UPDATE_SALARY_FAIL = "UPDATE_SALARY_FAIL",
    DELETE_EMPLOYEE = "DELETE_EMPLOYEE",
    DELETE_EMPLOYEE_SUCCESS = "DELETE_EMPLOYEE_SUCCESS",
    DELETE_EMPLOYEE_FAIL = "DELETE_EMPLOYEE_FAIL"
}

export default ActionTypes;