import styled from "styled-components";

const Flex = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 60vh;
`

export default Flex;
